import {
  LocationStrategy,
  PathLocationStrategy,
  TitleCasePipe,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/_current/environment';
import { TraceService, createErrorHandler } from '@sentry/angular';
import { AaptivReferenceService } from '@skyfit/core-web-library/aaptiv-services/aaptiv-reference';
import { AaptivAuthService } from '@skyfit/core-web-library/aaptiv-services/authenticaton';
import { AaptivCategoriesUtilService } from '@skyfit/core-web-library/aaptiv-services/categories';
import { AaptivFeedService } from '@skyfit/core-web-library/aaptiv-services/feed';
import { AaptivApiBasicInterceptor } from '@skyfit/core-web-library/aaptiv-services/http-interceptor';
import { PlatformService } from '@skyfit/core-web-library/aaptiv-services/platform';
import { AaptivTrainerService } from '@skyfit/core-web-library/aaptiv-services/trainer';
import { AaptivUserService } from '@skyfit/core-web-library/aaptiv-services/user';
import { AaptivWorkoutCardService } from '@skyfit/core-web-library/aaptiv-services/workout-card';
import { CastTimePipe } from '@skyfit/core-web-library/pipes';
import { AppEntityLoadService } from '@skyfit/core-web-library/services/app-entity-load';
import { AppEntityReferenceService } from '@skyfit/core-web-library/services/app-entity-reference';
import { AuthService } from '@skyfit/core-web-library/services/authentication';
import { BrowserService } from '@skyfit/core-web-library/services/browser';
import { CookieService } from '@skyfit/core-web-library/services/cookie-service';
import { FirestoreUtilsService } from '@skyfit/core-web-library/services/firebase';
import { SentryErrorInterceptor } from '@skyfit/core-web-library/services/http-interceptors';
import { LocalStorageService } from '@skyfit/core-web-library/services/local-storage';
import { WorkoutsSearchFilterService } from '@skyfit/core-web-library/services/search-filter';
import { SessionService } from '@skyfit/core-web-library/services/session';
import { SitesService } from '@skyfit/core-web-library/services/sites';
import { TenantsService } from '@skyfit/core-web-library/services/tenants';
import {
  AppThemeService,
  ThemesRemoteService,
} from '@skyfit/core-web-library/services/themes';
import { TranslatorService } from '@skyfit/core-web-library/services/translation';
import {
  UserRemoteService,
  UserService,
} from '@skyfit/core-web-library/services/user';
import { firstValueFrom } from 'rxjs';
import { AppStateService } from 'src/services/app-state/app-state.service';
import { CanDeactivateGuard } from 'src/services/page-navigation/can-deactivate.guard';
import { PageNavigationService } from 'src/services/page-navigation/page-navigation.service';
import { SplashScreenStateService } from 'src/services/splash-screen/splash-screen-state.service';
import { AuthActivateGuard } from './core/guards';

// TODO: theme service at some point

const appInitializerFn = (authService: AaptivAuthService) => () =>
  new Promise<void>(async (resolve, reject) => {
    await firstValueFrom(authService?.loadUserSession())
      .then((user) => {
        if (user?.id) {
          resolve(null);
        } else {
          authService?.tvLogoutRedirect();
          reject(null);
        }
      })
      .catch(() => {
        authService?.tvLogoutRedirect();
        reject(null);
      });
  }).catch(() => {
    authService?.tvLogoutRedirect();
  });

export const APP_PROVIDERS = [
  AppThemeService,
  AaptivUserService,
  AppEntityLoadService,
  AppEntityReferenceService,
  HttpClient,
  SessionService,
  SitesService,
  AaptivReferenceService,
  AaptivAuthService,
  AuthService,
  WorkoutsSearchFilterService,
  AaptivCategoriesUtilService,
  AaptivWorkoutCardService,
  AppStateService,
  UserRemoteService,
  TenantsService,
  ThemesRemoteService,
  UserService,
  FirestoreUtilsService,
  {
    provide: ErrorHandler,
    useValue: createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFn,
    multi: true,
    deps: [AaptivAuthService, TraceService],
  },
  { provide: APP_ID, useValue: 'serverApp' },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AaptivApiBasicInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SentryErrorInterceptor,
    multi: true,
  },
  {
    provide: 'env',
    useValue: environment,
  },
  // Pear Library Services
  TranslatorService,
  AaptivFeedService,
  BrowserService,
  CookieService,
  // pipes
  CastTimePipe,
  // Local services
  SplashScreenStateService,
  PageNavigationService,
  PlatformService,
  // Angular services

  TitleCasePipe,
  CanDeactivateGuard,
  AaptivTrainerService,
  { provide: LocationStrategy, useClass: PathLocationStrategy },
  // guards
  AuthActivateGuard,
  LocalStorageService,
];
