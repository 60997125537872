<div class="auth-container">
  <div class="header-container">
    <img class="header-logo" src="assets/img/aaptiv-logo.svg" />
  </div>
  <div class="login-actions-container">
    <div class="signin-form-container">
      <div class="auth-title">
        <h4>{{ 'auth.loginTitle' | translate | titlecase }}</h4>
      </div>
      <form
        autocomplete="off"
        class="auth-form"
        [formGroup]="signInForm"
        *ngIf="!isLockedOut; else lockedOut"
      >
        <div class="form-group">
          <input
            *ngIf="showEmailField"
            #emailInput
            #focusable
            type="email"
            class="form-control focusable"
            id="email"
            formControlName="email"
            (keydown)="onKeyPress($event)"
            [attr.aria-label]="'common.email' | translate"
            [attr.aria-describedby]="('common.email' | translate) + '-input'"
            placeholder="{{ 'common.enterEmail' | translate }}"
          />
          <div
            class="email-buttons"
            [style.justify-content]="emailError ? 'space-between' : 'flex-end'"
          >
            <button
              *ngIf="showEmailField"
              mat-button
              #focusable
              class="button-primary email-next focusable"
              (click)="onClickNext()"
              (keydown)="onKeyPress($event)"
            >
              {{ 'common.next' | translate }}
              <mat-icon>arrow_forward</mat-icon>
            </button>
            <mat-error class="email-error" *ngIf="emailError">{{
              'auth.emailFormatError' | translate
            }}</mat-error>
          </div>
        </div>
        <div class="form-group">
          <input
            *ngIf="!showEmailField"
            #passwordInput
            [type]="passwordInputType"
            #focusable
            class="form-control focusable password-input"
            formControlName="password"
            id="password"
            autocomplete="new-password"
            (keydown)="onKeyPress($event)"
            (keydown.enter)="onSoftKeyboardEnter($event)"
            [attr.aria-label]="'common.password' | translate"
            [attr.aria-describedby]="('common.password' | translate) + '-input'"
            placeholder="{{ 'common.password' | translate }}"
          />
          <!-- <div class="forgot-password-container" *ngIf="!showEmailField">
            <button class="focusable" mat-button (keydown)="onKeyPress($event)">
              Forgot password?
            </button>
          </div> -->
          <div class="password-buttons" *ngIf="!showEmailField">
            <!-- <button
              mat-button
              #focusable
              class="button-primary focusable"
              (click)="onClickBack($event)"
              (keydown)="onKeyPress($event)"
              (keydown.enter)="onClickBack($event)"
              (keydown.ArrowRight)="onArrowRight()"
            >
              {{ 'common.back' | translate }}
            </button> -->
            <button
              mat-button
              (click)="onClickPasswordVisibility($event)"
              (keydown)="onKeyPress($event)"
              (keydown.enter)="onClickPasswordVisibility($event)"
              (keydown.ArrowLeft)="onArrowLeft()"
              #focusable
              class="button-primary password-visibility focusable"
            >
              {{ showPassword ? 'Hide Password' : 'Show Pasword' }}
              <mat-icon *ngIf="!showPassword" class="visibility-icon"
                >visibility</mat-icon
              >
              <mat-icon *ngIf="showPassword" class="visibility-icon"
                >visibility_off</mat-icon
              >
            </button>
          </div>
        </div>
        <div class="auth-buttons" *ngIf="!showEmailField">
          <button
            mat-button
            (keydown)="onBackButtonClick($event)"
            (click)="onGoBack($event)"
            class="button-primary auth-button focusable"
            #backButton
          >
            <mat-icon>keyboard_backspace</mat-icon>
            {{ 'common.back' | translate }}
          </button>
          <button
            (click)="onSubmit()"
            (keydown)="onSignInButtonClick($event)"
            (click)="onSubmit()"
            mat-button
            #signInButton
            type="submit"
            class="button-primary auth-button focusable"
          >
            {{ 'auth.signIn' | translate }}
          </button>
        </div>
        <button
          mat-button
          class="focusable terms-button"
          (keydown)="onKeyPress($event)"
          (keydown.enter)="onOpenTerms()"
        >
          Terms and Conditions
        </button>
      </form>
      <div>
        <ng-template #lockedOut>
          <pear-alert-message
            [level]="alertLevelError"
            [messages]="['auth.lockedOut' | translate]"
          ></pear-alert-message>
        </ng-template>
      </div>
      <pear-alert-message
        *ngIf="hasFailed && !isLoading"
        [level]="alertLevelError"
        [messages]="[errorMessage]"
      ></pear-alert-message>
    </div>

    <div id="qr" class="qr-container">
      <qrcode
        colorDark="#ffffffff"
        colorLight="#1b1b1b"
        [qrdata]="qrUrl"
        ariaLabel="Qr code"
        cssClass="qr-code"
      ></qrcode>
      <p class="qr-instructions">{{ 'auth.qrInstructions' | translate }}</p>
    </div>
  </div>
</div>
<app-about *ngIf="!keyboardIsOpen"></app-about>
