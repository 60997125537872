import { baseStagingEnvironment } from '@environments/_base/staging.environment';
import { TVEnvironmentSettings } from '../tv-environment-settings';

export const environment: TVEnvironmentSettings = {
  ...baseStagingEnvironment,
  firebase: {
    apiKey: 'AIzaSyDDcRNzmzE-giCaoASSQUyM6kiXT5Rne8E',
    authDomain: 'pearsports-staging.firebaseapp.com',
    projectId: 'pearsports-staging',
    storageBucket: 'pearsports-staging.appspot.com',
    messagingSenderId: '380839439958',
    appId: '1:380839439958:web:398c003092d457f25bd5f7',
  },
  pearTVConfig: {
    pearProUrl: 'aaptiv-pro-staging.pearsports.com',
    appHostname: 'localhost',
  },
  datadog: {
    clientToken: 'pubba37f36c70fe2f3995ff50ab4eb2f8ee',
  },
};
