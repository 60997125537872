<div class="navbar-pages-container">
  <app-grid
    *ngIf="visible"
    class="navbar-container"
    [activeIndex]="activeIndex"
    [itemsPerRow]="1"
    [allowLeftMovement]="false"
    [allowRightMovement]="false"
    [items]="navIcons"
    [currentNodeIndex]="currentNodeIndex"
    (updateIndex)="onUpdateIndex($event)"
    (rightEdgeMovement)="onRightEdgeMovement()"
    tabindex="-1"
    disabled
  >
    <img
      tabindex="-1"
      disabled
      class="nav-icon navbar-aaptiv-logo"
      [src]="imageUrl"
      alt="Aaptiv Logo"
    />
    <app-icon
      #home="routerLinkActive"
      disabled
      [routerLink]="homeRouterLink"
      (click)="onSelectPage($event, homeRouterLink)"
      (keydown.enter)="onSelectPage($event, homeRouterLink)"
      (isActiveChange)="setActiveIcon()"
      routerLinkActive="selected"
    >
      <mat-icon class="nav-icon">home</mat-icon>
    </app-icon>
    <app-icon
      #profile="routerLinkActive"
      disabled
      [routerLink]="profileRouterLink"
      (click)="onSelectPage($event, profileRouterLink)"
      (keydown.enter)="onSelectPage($event, profileRouterLink)"
      (isActiveChange)="setActiveIcon()"
      routerLinkActive="selected"
    >
      <mat-icon class="nav-icon">person</mat-icon>
    </app-icon>
    <!-- <app-icon [routerLink]="settingsRouterLink">
      <mat-icon class="nav-icon">settings</mat-icon>
    </app-icon> -->
    <app-icon
      #search="routerLinkActive"
      disabled
      [routerLink]="searchRouterLink"
      routerLinkActive="selected"
      (click)="onSelectPage($event, searchRouterLink)"
      (keydown.enter)="onSelectPage($event, searchRouterLink)"
      (isActiveChange)="setActiveIcon()"
    >
      <mat-icon class="nav-icon">search</mat-icon>
    </app-icon>
  </app-grid>
  <div class="pages-container">
    <ng-content></ng-content>
  </div>
</div>
