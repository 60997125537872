<div class="terms-container">
  <div class="terms-header">
    <h2 class="title">END USER LICENSE AGREEMENT AND TERMS OF SERVICE</h2>
    <h3 class="effective-date">Effective Date: December 20, 2018</h3>
  </div>
  <p>
    This End User License Agreement and Terms of Service (this “<u>EULA</u>”) is
    a binding contract between you, an individual user (“<u>you</u>”) and Pear
    Health Labs Inc. (dba Aaptiv) (“<u>Aaptiv</u>,” “<u>we</u>,” “<u>us</u>” or
    “<u>our</u>”) governing your use of the mobile software applications that
    Aaptiv makes available for download (individually and collectively, the
    “<u>App</u>”), the related website located at www.aaptiv.com and any other
    online properties owned or controlled by or on behalf of Aaptiv
    (collectively with the App, the “<u>Service</u>”). BY INSTALLING OR
    OTHERWISE ACCESSING OR USING THE SERVICE, YOU AGREE THAT YOU HAVE READ,
    UNDERSTOOD, AND AGREE TO BE BOUND BY THIS EULA. IF YOU DO NOT AGREE TO THE
    TERMS OF THIS EULA, THEN YOU SHOULD NOT USE THE SERVICE.
  </p>
  <p>
    <u>Material Terms</u> : As provided in greater detail in this EULA (and
    without limiting the express language of this EULA), you acknowledge the
    following:
  </p>
  <p class="bullet">
    ● the Service is licensed, not sold, to you, and you may use the Service
    only as set forth in this EULA;
  </p>
  <p class="bullet">
    ● you use the Service at your sole risk, and you should consult with a
    physician or other health care professional before commencing use of the
    Service;
  </p>
  <p class="bullet">
    ● the use of the Service may be subject to separate third-party terms of
    service and fees, including, without limitation, your mobile network
    operator’s (the “<u>Carrier</u>”) terms of service and fees, including fees
    charged for data usage and overage, which are your sole responsibility;
  </p>
  <p class="bullet">
    ● you consent to the collection, use, and disclosure of your personally
    identifiable information in accordance with Aaptiv’s Privacy Policy
    available at www.aaptiv.com/privacy (“<u>Privacy Policy</u>”);
  </p>
  <p class="bullet">
    ● we provide the App to you on an “as is” basis without warranties of any
    kind and Aaptiv’s liability to you is limited;
  </p>
  <p class="bullet">
    ● disputes arising between you and Aaptiv will be resolved by binding
    arbitration. By accepting this EULA, as provided in greater detail in
    section 11 below, you and Aaptiv are each waiving the right to a trial by
    jury or to participate in a class action;
  </p>
  <p>1. General Terms and Conditions.</p>
  <div>
    <p>
      a. <u>Changes to this EULA</u>. You understand and agree that we may
      change this EULA at any time without prior notice. The revised EULA will
      become effective at the time of posting unless specified otherwise. Any
      use of the Service <u>after the effective date</u> will constitute your
      acceptance of such revised EULA. If you find any change to this EULA or
      the Service unacceptable, then your sole remedy is to stop accessing,
      browsing, and otherwise using the Service.
    </p>
    <p>
      b. <u>Privacy Policy</u>. Your access to and use of the Service is also
      subject to Aaptiv’s Privacy Policy, the terms and conditions of which are
      incorporated herein by reference.
    </p>
    <p>
      c. <u>Jurisdictional Issues</u>. The Service is controlled and operated by
      Aaptiv from its offices in the State of New York. Aaptiv makes no
      representation that materials on the Service are appropriate, lawful, or
      available for use in any locations other than the United States of
      America. Those who choose to access or use the Service from locations
      outside the United States of America do so on their own initiative and are
      responsible for compliance with local laws, if and to the extent local
      laws are applicable. Access to the Service from jurisdictions where the
      contents or practices of the Service are illegal, unauthorized, or
      penalized is strictly prohibited.
    </p>
    <p>
      d. <u>Eligibility</u>. The service is not for persons under the age of 13
      (or under 16 for individuals in the European Union) or for any users
      previously suspended or removed from the service by Aaptiv. IF YOU ARE
      UNDER 13 YEARS OF AGE (or under 16 in the EU), YOU MUST NOT USE OR ACCESS
      THE SERVICE AT ANY TIME OR IN ANY MANNER. By accessing or using the
      Service, you affirm that either you are at least 18 years of age or you
      have been authorized to use the Service by your parent or legal guardian
      who is at least 18 years of age.
    </p>
  </div>
  <p>2. The Service.</p>
  <div>
    <p>
      a. <u>Description</u>. The Service provides you with the opportunity to
      take workout classes (each, a “<u>Class</u>”) and receiving coaching from
      trainers solely for your personal use. Classes are available for a variety
      of skill levels, for a variety of activities, and for varying durations.
      Not all classes will be available in perpetuity and Classes are subject to
      change at any time with or without notice and without any liability to
      you.
    </p>
    <p>
      b. <u>Mobile Services</u>. The Service will be accessible via a mobile
      phone, tablet, or other wireless device (collectively, “
      <u>Mobile Services</u>”). Your mobile carrier’s normal messaging, data,
      and other rates and fees will apply to your use of the Mobile Services. In
      addition, downloading, installing, or using certain Mobile Services may be
      prohibited or restricted by your mobile carrier, and not all Mobile
      Services may work with all carriers or devices. Therefore, you are solely
      responsible for checking with your mobile carrier to determine if the
      Mobile Services are available for your mobile device(s), what
      restrictions, if any, may be applicable to your use of the Mobile
      Services, and how much they will cost you.
    </p>
  </div>
  <p>3. Registration.</p>
  <div>
    <p>
      a. <u>Log-In Credentials</u>. While you may always browse the
      public-facing portions of the Service without registering with us, in
      order to enjoy the full benefits of the Service, you must download the App
      and register an account with us (an “<u>Account</u>”).
    </p>
    <p>
      b. <u>Account Security</u>. You are responsible for the security of your
      Account, and are fully responsible for all activities that occur through
      the use of your credentials. You may not share the credentials for your
      Account with any third party. You agree to notify Aaptiv immediately at
      support&#64;aaptiv.com if you suspect or know of any unauthorized use of your
      log-in credentials or any other breach of security with respect to your
      Account. Aaptiv will not be liable for any loss or damage arising from
      unauthorized use of your credentials prior to you notifying Aaptiv of such
      unauthorized use or loss of your credentials.
    </p>
    <p>
      c. <u>Accuracy of Information</u>. When creating an Account, you will
      provide true, accurate, current, and complete information to Aaptiv. You
      will update the information about yourself promptly, and as necessary, to
      keep it current and accurate. We reserve the right to disallow, cancel,
      remove, or reassign certain usernames and permalinks in appropriate
      circumstances, as determined by us in our sole discretion, and may, with
      or without prior notice, suspend or terminate your Account if activities
      occur on your Account which, in our sole discretion, would or might
      constitute a violation of this EULA, cause damage to or impair the
      Service, infringe or violate any third party rights, damage or bring into
      disrepute the reputation of Aaptiv, or violate any applicable laws or
      regulations. If messages sent to the e-mail address you provide are
      returned as undeliverable, then Aaptiv may terminate your Account
      immediately without notice and without any liability.
    </p>
  </div>
  <p>4. Subscriptions and Payment.</p>
  <div>
    <p>
      a. <u>Initial Subscription Payment</u>. To enjoy the full benefits of the
      Service, including Classes, you must purchase a subscription to the
      Service. You will be charged the stated subscription fee and any
      applicable taxes and service fees upon enrollment, unless your membership
      starts with a free trial, as detailed below.
    </p>
    <p>
      b. <u>Free Trials</u>. If your Aaptiv membership starts with a free trial
      period, the duration of which is specified during enrollment, WE WILL BILL
      YOUR DESIGNATED PAYMENT METHOD FOR THE SUBSCRIPTION FEE AT THE END OF THE
      FREE TRIAL PERIOD, unless you cancel prior to the end of the free trial
      period. By providing your payment details when enrolling in the free
      trial, you agree that Aaptiv may charge the subscription fee to your
      designated payment method upon conclusion of the free trial period. Free
      trials are only available to first-time subscribers. Aaptiv reserves the
      right, in its sole discretion, to determine your free trial eligibility.
    </p>
    <p>
      b. <u>Automatically Renewable Subscriptions</u>. By purchasing a
      subscription to the Service, you agree that, upon expiration of the
      initial subscription term, your subscription will automatically renew for
      successive periods of the same duration as the initial subscription unless
      and until you cancel your subscription.
    </p>
    <p>
      c. <u>Cancellation</u>. You may cancel your subscription to the Service at
      any time, after which Aaptiv will not automatically renew your
      subscription.
    </p>
    <p>
      d. <u>Recurring Charges</u>. YOU AUTHORIZE AAPTIV TO CHARGE YOUR ACCOUNT
      USING THE PAYMENT METHOD ON FILE FOR YOU TO PAY FOR ANY RENEWAL
      SUBSCRIPTION. You will be billed for the same subscription plan (or the
      most similar subscription plan, if your prior plan is no longer available)
      at the then-current applicable subscription price plus any applicable
      taxes. We will process payment for any renewal subscription using the same
      billing cycle as your current subscription. Additional terms and
      conditions may apply upon renewal, and subscription fees may change at any
      time, to the fullest extent permitted under applicable law.
    </p>
    <p>
      e. <u>Refund Policy</u>. Any payment made through Aaptiv’s website or
      through the Google Play Store is refundable on a case-by-case basis. To
      request a refund, reach out to us at support&#64;aaptiv.com. For payments made
      via Apple’s App Store, refunds are subject to Apple’s terms and
      conditions. Subscriptions that are cancelled during their free trial
      period (if any) will not incur any payment obligation. If a subscription
      is refunded, that subscription may be cancelled, and access to the service
      may be terminated immediately. Following any cancellation without a
      refund, however, you will continue to have access to the service through
      the end of your current billing period. At any time, and for any reason,
      we may provide a refund, discount, or other consideration to some or all
      of our members ("Credits"). The amount and form of such Credits, and the
      decision to provide them, are at our sole and absolute discretion.
    </p>
    <p>
      f. <u>Price Promotions</u>. At the end of any promotion, Aaptiv may, at
      its sole discretion, revert a member’s price per period to the current
      standard retail pricing for that membership type. Aaptiv may also
      discontinue certain pricing promotions at any time at its sole discretion.
    </p>
    <p>
      g. <u>Third Party Subscriptions</u>. If you receive access to the Service
      through a third party, such as an employer (a “Third Party Subscriber”),
      your use of and access to the service may be subject to the terms of
      agreements entered into by the Third Party Subscriber as well as this
      EULA. Please consult with the Third Party Subscriber for details.
    </p>
  </div>
  <p>5. Intellectual Property Rights.</p>
  <div>
    <p>
      a. <u>License</u>. Subject to your complete and ongoing compliance with
      this Agreement, Aaptiv hereby grants you a personal, limited, revocable,
      non-transferable license to access and use the Service solely for your
      personal, non-commercial use. Aaptiv reserves all rights not expressly
      granted to you.
    </p>
    <p>
      b. <u>Content</u>. Except for User Content, the content that Aaptiv
      provides to end users on or through the Service, including without
      limitation, any Class (including the programming of such Class), text,
      graphics, photos, software, sound recordings (and the musical works
      embodied therein), and interactive features, may be protected by copyright
      or other intellectual property rights and owned by Aaptiv or its third
      party licensors (collectively, the “<u>Aaptiv Content</u>”). You may not
      copy, reproduce, upload, republish, broadcast, transmit, retransmit, Post,
      modify, create derivative works of, publicly perform, publicly display,
      use for commercial purpose or distribute any materials from the Service
      without prior express written permission of the owner of such material or
      as permitted by the Service’s intended functionalities. Your use of Aaptiv
      Content must be in compliance with applicable law. Aaptiv reserves all
      rights on the part of its licensors; users are not permitted to infringe
      the rights of the copyright owners of the sound recordings included on the
      Service or any musical works embodied therein (collectively “Music”).
      Classes are offered for individual use only by a single user per Account
      and users are not granted any commercial, sale, resale, reproduction,
      distribution or promotional use rights for the Music used with any
      Classes. The unauthorized reproduction or distribution of the Music is
      expressly prohibited any may violated applicable law and subject you to
      liability for copyright infringement.
    </p>
    <p>
      c. <u>Marks</u>. Aaptiv trademarks, service marks, and logos (the “
      <u>Aaptiv Trademarks</u>”) used and displayed on the Service are Aaptiv’s
      registered and unregistered trademarks or service marks. You may not use
      any Trademarks as part of a link to or from the Service without Aaptiv’s
      prior express written consent. You may not remove any Trademarks
      identifying the ownership or origin of any Aaptiv Content. All goodwill
      generated from the use of any Aaptiv Trademark will inure solely to
      Aaptiv’s benefit.
    </p>
  </div>
  <p>6. User Content.</p>
  <div>
    <p>
      a. <u>Definition</u>. “<u>User Content</u>” means any content that users
      upload, post or transmit (collectively, “<u>Post</u>”) to or through the
      Service including, without limitation, any text, comments and other works
      subject to protection under the laws of the United States or any other
      jurisdiction, including, but not limited to, patent, trademark, trade
      secret, and copyright laws, and excludes any and all Aaptiv Content.
    </p>
    <p>
      b. <u>Screening User Content</u>. Aaptiv offers end users the ability to
      submit User Content to or transmit User Content through the Service.
      Aaptiv does not pre-screen any User Content, but reserves the right to
      remove, disallow, block, or delete any User Content in its sole
      discretion. Aaptiv does not guarantee the accuracy, integrity,
      appropriateness, availability, or quality of any User Content, and under
      no circumstances will Aaptiv be liable in any way for any User Content.
    </p>
    <p>
      c. <u>Licenses to User Content</u>. While you retain ownership of any
      rights you may have in your User Content, you hereby grant Aaptiv an
      unrestricted, assignable, sublicensable, revocable, royalty-free license
      throughout the universe to reproduce, distribute, publicly display,
      communicate to the public, publicly perform (including by means of digital
      audio transmissions and on a through-to-the-audience basis), make
      available, create derivative works from, retransmit from External Sites,
      and otherwise exploit and use (collectively, “ <u>Use</u>”) all or any
      part of all User Content you Post to or through the Service by any means
      and through any media and formats now known or hereafter developed, for
      the purposes of (i) advertising, marketing, and promoting Aaptiv and the
      Service; (ii) displaying and sharing your User Content to other users of
      the Service; and (iii) providing the Service as authorized by this EULA.
      You further grant Aaptiv a royalty-free license to use your user name,
      image, voice, and likeness to identify you as the source of any of your
      User Content. Any User Content posted by you to or through the Service or
      transmitted to Aaptiv will be considered non-confidential and
      non-proprietary, and treated as such by Aaptiv, and may be used by Aaptiv
      in accordance with this EULA without notice to you and without any
      liability to Aaptiv.
    </p>
    <p>
      d. <u>You Must Have Rights to the Content You Post</u>. You represent and
      warrant that: (i) you own the User Content Posted by you on or through the
      Service or otherwise have the right to grant the license set forth in this
      EULA; (ii) the Posting and Use of your User Content on or through the
      Service does not violate the privacy rights, publicity rights, copyrights,
      contract rights, intellectual property rights, or any other rights of any
      person, including, but not limited to, the rights of any person visible in
      any of your User Content; (iii) the Posting of your User Content on the
      Service will not require us to obtain any further licenses from or pay any
      royalties, fees, compensation, or other amounts or provide any attribution
      to any third parties; and (iv) the Posting of your User Content on the
      Service does not result in a breach of contract between you and a third
      party. You agree to pay all monies owing to any person as a result of your
      Posting your User Content on the Service.
    </p>
    <p>
      e. <u>Waiver of Rights to User Content</u>. By Posting User Content to or
      through the Service, you waive any rights to prior inspection or approval
      of any marketing or promotional materials related to such User Content.
      You also waive any and all rights of privacy, publicity, or any other
      rights of a similar nature in connection with your User Content, or any
      portion thereof. To the extent any moral rights are not transferable or
      assignable, you hereby waive and agree never to assert any and all moral
      rights, or to support, maintain, or permit any action based on any moral
      rights that you may have in or with respect to any User Content you Post
      to or through the Service.
    </p>
    <p>
      f. <u>Objectionable Content</u>. You agree not to Post any User Content to
      the Service that is or could be interpreted to be (i) abusive, bullying,
      defamatory, harassing, harmful, hateful, inaccurate, infringing, libelous,
      objectionable, obscene, offensive, pornographic, shocking, threatening,
      unlawful, violent, vulgar, or in violation of any applicable laws
      (including laws related to speech); or (ii) promoting any product, good,
      or service, or bigotry, discrimination, hatred, intolerance, racism, or
      inciting violence (including suicide) (collectively, “<u
        >Objectionable Content</u
      >”). The Posting of any Objectionable Content may subject you to third
      party claims and none of the rights granted to you in this EULA may be
      raised as a defense against such third party claims. If you encounter any
      Objectionable Content on the Service, then please immediately email
      Support&#64;aaptiv.com. Aaptiv in its sole discretion may take any actions it
      deems necessary and/or appropriate against any User who Posts
      Objectionable Content on the Service.
    </p>
  </div>
  <p>7. Restrictions on Use of the Service.</p>
  <div>
    <p>
      a. In addition to any other restrictions set forth in this EULA, and
      without limiting those restrictions, when using the Service, you agree not
      to: make unauthorized copies or derivative works of any content made
      available on or through the Service (including making more than one copy
      on any devices owned, controlled or in possession of any User and only as
      permitted by the functionality of the Service); use any device, software,
      or routine to interfere or attempt to interfere with the proper working of
      the Service; attempt to decipher, decompile, disassemble, or reverse
      engineer any of the software or source code comprising or making up the
      Service; delete or alter any material Aaptiv or any other person or entity
      Posts on the Service; frame or link to any of the materials or information
      available on the Service; alter, deface, mutilate, or otherwise bypass any
      approved software through which the Service is made available; use any
      trademarks, service marks, design marks, logos, photographs, or other
      content belonging to Aaptiv or obtained from the Service; provide any
      false personal information to Aaptiv; create a new account with Aaptiv,
      without Aaptiv’s express written consent, if Aaptiv has previously
      disabled an account of yours; solicit or collect personal information from
      other Users; disclose personal information about a third person on the
      Service or obtained from the Service without the consent of that person;
      use the Service to send emails or other communications to persons who have
      requested that you not send them communications; use the Service, without
      Aaptiv’s express written consent, for any commercial or unauthorized
      purpose, including communicating or facilitating any commercial
      advertisement or solicitation or spamming; or violate any applicable
      federal, state, or local laws or regulations or the terms of this EULA.
    </p>
  </div>
  <p>
    8. External Sites. The Service may contain links to, or the ability to share
    information with, third party websites (“<u>External Sites</u>”). Aaptiv
    does not endorse any External Sites or the content made available on such
    External Sites. Aaptiv is not responsible for the content of any External
    Sites and does not make any representations regarding the content or
    accuracy of any materials on such External Sites. You agree that Aaptiv will
    have no liability to you arising from your use, engagement, exposure to, or
    interaction with any External Sites.
  </p>
  <p>
    9. Feedback. While we are continually working to develop and evaluate our
    own product ideas and features, we know we don’t have all the answers. We
    therefore welcome your feedback, comments, and suggestions. If you choose to
    contribute by sending Aaptiv any ideas (“<u>Feedback</u>”), then regardless
    of what your accompanying communication may say, the following terms will
    apply. Accordingly, by sending Feedback to Aaptiv, you agree that: a. Aaptiv
    has no obligation to review, consider, or implement your Feedback, or to
    return to you all or part of any Feedback for any reason; b. Feedback is
    provided on a non-confidential basis, and Aaptiv is not under any obligation
    to keep any Feedback you send confidential or to refrain from using or
    disclosing it in any way; and c. You irrevocably grant Aaptiv perpetual and
    unlimited permission to Use the Feedback and derivatives thereof for any
    purpose and without restriction, free of charge and without attribution of
    any kind, including by making, using, selling, offering for sale, importing,
    and promoting commercial products and services that incorporate or embody
    Feedback, whether in whole or in part, and whether as provided or as
    modified.
  </p>
  <p>
    10. Notice and Procedure for Making Claims of Copyright or Other
    Intellectual Property Infringements.
  </p>
  <div>
    <p>
      a. <u>Respect of Third Party Rights</u>. Aaptiv respects the intellectual
      property of others and takes the protection of intellectual property very
      seriously, and we ask our users to do the same.
    </p>
    <p>
      b. <u>Repeat Infringer Policy</u>. Aaptiv’s intellectual property policy
      is to (i) remove or disable access to material that Aaptiv believes in
      good faith, upon notice from an intellectual property owner or his or her
      agent, is infringing the intellectual property of a third party by being
      made available through the Service; and (ii) remove any User Content
      uploaded to the Service by “repeat infringers.” Aaptiv considers a “repeat
      infringer” to be any user that has uploaded User Content or Feedback to or
      through the Service and for whom Aaptiv has received more than two
      takedown notices compliant with the provisions of 17 U.S.C. § 512 with
      respect to such User Content or Feedback. Aaptiv has discretion, however,
      to terminate the Account of any user after receipt of a single
      notification of claimed infringement or upon Aaptiv’s own determination.
    </p>
    <p>
      c. <u>Procedure for Reporting Claimed Infringement</u>. If you believe
      that any content made available on or through the Service has been used or
      exploited in a manner that infringes an intellectual property right you
      own or control, then please promptly send a “
      <u>Notification of Claimed Infringement</u>” containing the following
      information to the Designated Agent identified below. Your Notification of
      Claimed Infringement may be shared by Aaptiv with the user alleged to have
      infringed a right you own or control, and you hereby consent to Aaptiv
      making such disclosure. Your communication must include substantially the
      following: i. A physical or electronic signature of a person authorized to
      act on behalf of the owner of the work(s) that has/have been allegedly
      infringed; ii. Identification of works or materials being infringed, or,
      if multiple works are covered by a single notification, then a
      representative list of such works; iii. Identification of the specific
      material that is claimed to be infringing or to be the subject of
      infringing activity and that is to be removed or access to which is to be
      disabled, and information reasonably sufficient to permit Aaptiv to locate
      the material; iv. Information reasonably sufficient to permit Aaptiv to
      contact you, such as an address, telephone number, and, if available, an
      electronic mail address at which you may be contacted; v. A statement that
      you have a good faith belief that the use of the material in the manner
      complained of is not authorized by the copyright owner, its agent, or the
      law; and vi. A statement that the information in the notification is
      accurate, and under penalty of perjury, that you are authorized to act on
      behalf of the owner of an exclusive right that is allegedly infringed.
    </p>
    <p>
      d. <u>Designated Agent Contact Information</u>. Aaptiv’s designated agent
      for receipt of Notifications of Claimed Infringement (the “
      <u>Designated Agent</u>”) can be contacted at:
    </p>
  </div>
  <div>
    <p>Via E-mail: copyright&#64;aaptiv.com</p>
    <p>Via U.S. Mail: Aaptiv – PO Box 270, New Rochelle NY 10804</p>
  </div>
  <div>
    <p>
      e. <u>Counter Notification</u>. If you receive a notification from Aaptiv
      that material made available by you on or through the Service has been the
      subject of a Notification of Claimed Infringement, then you will have the
      right to provide Aaptiv with what is called a “
      <u>Counter Notification</u>.” To be effective, a Counter Notification must
      be in writing, provided to Aaptiv’s Designated Agent through one of the
      methods identified in Section 10.d and include substantially the following
      information: i. A physical or electronic signature of the subscriber; ii.
      Identification of the material that has been removed or to which access
      has been disabled and the location at which the material appeared before
      it was removed or access to it was disabled; iii. A statement under
      penalty of perjury that the subscriber has a good faith belief that the
      material was removed or disabled as a result of mistake or
      misidentification of the material to be removed or disabled; and iv. The
      subscriber’s name, address, and telephone number, and a statement that the
      subscriber consents to the jurisdiction of Federal District Court for the
      judicial district in which the address is located, or if the subscriber’s
      address is outside of the United States, then for any judicial district in
      which Aaptiv may be found, and that the subscriber will accept service of
      process from the person who provided notification under Section 10.d above
      or an agent of such person.
    </p>
    <p>
      f. <u>Reposting of Content Subject to a Counter Notification</u>. If you
      submit a Counter Notification to Aaptiv in response to a Notification of
      Claimed Infringement, then Aaptiv will promptly provide the person who
      provided the Notification of Claimed Infringement with a copy of your
      Counter Notification and inform that person that Aaptiv will replace the
      removed User Content or Feedback or cease disabling access to it in 10
      business days, and Aaptiv will replace the removed User Content or
      Feedback and cease disabling access to it not less than 10, nor more than
      14, business days following receipt of the Counter Notification, unless
      Aaptiv’s Designated Agent receives notice from the party that submitted
      the Notification of Claimed Infringement that such person has filed an
      action seeking a court order to restrain the user from engaging in
      infringing activity relating to the material on Aaptiv’s system or
      network.
    </p>
    <p>
      g.
      <u
        >False Notifications of Claimed Infringement or Counter Notifications </u
      >. The Copyright Act provides that:
    </p>
  </div>
  <div>
    <p>
      [a]ny person who knowingly materially misrepresents under [Section 512 of
      the Copyright Act (17 U.S.C. § 512)] (1) that material or activity is
      infringing, or (2) that material or activity was removed or disabled by
      mistake or misidentification, will be liable for any damages, including
      costs and attorneys’ fees, incurred by the alleged infringer, by any
      copyright owner or copyright owner’s authorized licensee, or by a service
      provider, who is injured by such misrepresentation, as the result of
      [Aaptiv] relying upon such misrepresentation in removing or disabling
      access to the material or activity claimed to be infringing, or in
      replacing the removed material or ceasing to disable access to it. 17
      U.S.C. § 512(f).
    </p>
  </div>
  <div>
    <p>
      Aaptiv reserves the right to seek damages from any party that submits a
      Notification of Claimed Infringement or Counter Notification in violation
      of the law.
    </p>
  </div>
  <p>11. Dispute Resolution.</p>
  <div>
    <p>
      a. <u>General</u>. You and Aaptiv agree that any dispute arising out of or
      in any way related to this EULA or your use of the App, whether based in
      contract, tort, statute, fraud, misrepresentation, or any other legal
      theory, and regardless of whether a claim arises during or after the
      termination of this EULA, will be resolved by binding arbitration. YOU
      UNDERSTAND AND AGREE THAT, BY ENTERING INTO THIS EULA, YOU AND AAPTIV ARE
      EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
      ACTION.
    </p>
    <p>
      b. <u>Exceptions</u>. Notwithstanding Section 11.a above, nothing in this
      EULA will be deemed to waive, preclude, or otherwise limit the right of
      either party to: (i) bring an individual action in small claims court;
      (ii) pursue an enforcement action through the applicable federal, state,
      or local agency if that action is available; (iii) seek injunctive relief
      in aid of arbitration from a court of competent jurisdiction; or (iv) to
      file suit in a court of law to address an intellectual property
      infringement claim.
    </p>
    <p>
      c. <u>Arbitrator</u>. Any arbitration between you and Aaptiv will be
      governed by the Federal Arbitration Act and the Commercial Dispute
      Resolution Procedures and Supplementary Procedures for Consumer Related
      Disputes (collectively, “<u>AAA Rules</u>”) of the American Arbitration
      Association (“<u>AAA</u>”), as modified by this EULA, and will be
      administered by the AAA. The AAA Rules and filing forms are available
      online at www.adr.org. The arbitrator has exclusive authority to resolve
      any dispute relating to the interpretation, applicability, or
      enforceability of this binding arbitration agreement.
    </p>
    <p>
      d. <u>Notice; Process</u>. A party who intends to seek arbitration must
      first send a written notice of the dispute to the other party by U.S. Mail
      (“<u>Notice</u>”). Aaptiv’s address for Notice is: Aaptiv – PO Box 270,
      New Rochelle NY 10804, Attn: General Counsel. The Notice must: (i)
      describe the nature and basis of the claim or dispute; and (ii) set forth
      the specific relief sought (“<u>Demand</u>”). The parties will make good
      faith efforts to resolve the claim directly, but if the parties do not
      reach an agreement to do so within 30 days after the Notice is received,
      you or Aaptiv may commence an arbitration proceeding.
    </p>
    <p>
      e. <u>No Class Actions</u>. YOU AND AAPTIV AGREE THAT EACH MAY BRING
      CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT
      AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
      PROCEEDING. Further, unless both you and Aaptiv agree otherwise, the
      arbitrator may not consolidate more than one person’s claims, and may not
      otherwise preside over any form of a representative or class proceeding.
    </p>
    <p>
      f. <u>Modifications to this Arbitration Provision</u>. Except as otherwise
      provided in this EULA, if Aaptiv makes any future change to this
      arbitration provision, other than a change to Aaptiv’s address for Notice,
      then you may reject the change by sending us written notice within 30 days
      of the change to Aaptiv’s address for Notice, in which case this
      arbitration provision, as in effect immediately prior to the changes you
      rejected, will continue to govern any disputes between you and Aaptiv.
    </p>
    <p>
      g. <u>Enforceability</u>. If Section 11.f above is found to be
      unenforceable or if the entirety of this Section 11 is found to be
      unenforceable, then the entirety of this Section 11 will be null and void.
    </p>
  </div>
  <p>
    12. Physical Activity Disclaimer. If you have any medical conditions or are
    thinking about starting an exercise program or engaging in strenuous or
    unusual physical activity, you should consult your doctor first. Without
    limitation of any other terms in this EULA, we do not make any
    representations or warranties that the app is intended to and/or does
    diagnose, treat, cure, or prevent any allergies or other medical disorders
    or conditions, and you hereby acknowledge this disclaimer and that we are
    not engaged in providing you with a medical device, medical advice and/or
    healthcare services by providing you with access to the app. We are not
    responsible in any way for any health problems or injuries, including,
    without limitation, incapacity or death, which may result from or be related
    to your use of or inability to use the App.
  </p>
  <p>13. Limitation of Liability and Disclaimer of Warranties.</p>
  <div>
    <p>
      a. AAPTIV, ITS AFFILIATES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS,
      EMPLOYEES, AGENTS, SUPPLIERS AND LICENSORS (COLLECTIVELY, THE “
      <u>AAPTIV PARTIES</u>”) MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
      SERVICE AND ANY CONTENT AVAILABLE ON THE SERVICE. THE AAPTIV PARTIES WILL
      NOT BE SUBJECT TO LIABILITY FOR THE TRUTH, ACCURACY, OR COMPLETENESS OF
      ANY INFORMATION CONVEYED TO ANY USER, OR FOR ANY DELAYS OR INTERRUPTIONS
      OF THE DATA OR INFORMATION STREAM FROM WHATEVER CAUSE. AS A USER, YOU
      AGREE THAT YOU USE THE SERVICE AND ANY CONTENT THEREON AT YOUR OWN RISK.
      YOU ARE SOLELY RESPONSIBLE FOR ALL CONTENT YOU UPLOAD TO THE SERVICE.
    </p>
    <p>
      b. THE AAPTIV PARTIES DO NOT WARRANT THAT THE SERVICE WILL OPERATE ERROR
      FREE, OR THAT THE SERVICE AND ANY CONTENT THEREON ARE FREE OF COMPUTER
      VIRUSES OR SIMILAR DESTRUCTIVE FEATURES. IF YOUR USE OF THE SERVICE OR ANY
      CONTENT THEREON RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT
      OR DATA, THEN NO AAPTIV PARTY WILL BE RESPONSIBLE FOR THOSE COSTS.
    </p>
    <p>
      c. THE SERVICE AND ALL CONTENT THEREON ARE PROVIDED ON AN “AS IS” AND “AS
      AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND. ACCORDINGLY, THE
      AAPTIV PARTIES DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
      WARRANTIES OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTY
      RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE.
    </p>
    <p>
      d. IN NO EVENT WILL ANY AAPTIV PARTY BE LIABLE FOR ANY SPECIAL, INDIRECT,
      PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES
      RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION RESULTING FROM, OR IN
      CONNECTION WITH, THE USE OR INABILITY TO USE THE SERVICE AND ANY CONTENT
      THEREON, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
      OR ANY OTHER LEGAL THEORY, EVEN IF SUCH AAPTIV PARTY HAS BEEN ADVISED OF
      THE POSSIBILITY OF SUCH DAMAGES. AAPTIV’S LIABILITY, AND THE LIABILITY OF
      ANY OTHER AAPTIV PARTIES, TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE
      IS LIMITED TO THE GREATER OF THE FEES YOU HAVE PAID US AND U.S. $100.
    </p>
  </div>
  <p>14. Third Party Disputes.</p>
  <div>
    <p>
      a. To the fullest extent permitted by law, any dispute you have with any
      third party arising out of your use of the service, including, by way of
      example and not limitation, any carrier, copyright owner, or other user,
      is directly between you and such third party, and you irrevocably release
      the Aaptiv parties from any and all claims, demands and damages (actual
      and consequential) of every kind and nature, known and unknown, arising
      out of or in any way connected with such disputes.
    </p>
    <p>
      b. The owners of any content licensed to Aaptiv for use on the Service are
      intended beneficiaries of this EULA and shall have the right to enforce
      this EULA against you for any unauthorized use of their content in any
      court of competent jurisdiction. The provisions of Section 11 do not apply
      to any dispute between you and a third party licensor of content to
      Aaptiv.
    </p>
  </div>
  <p>
    15. Indemnification. To the fullest extent permitted by law, you agree to
    defend, indemnify, and hold harmless the Aaptiv Parties from and against any
    claims, actions or demands, including, without limitation, reasonable legal
    and accounting fees, arising or resulting from (a) your breach of this EULA;
    (b) your access to, use, or misuse of Aaptiv Content or the Service; or (c)
    your User Content. Aaptiv will provide notice to you of any such claim,
    suit, or proceeding. Aaptiv reserves the right to assume the exclusive
    defense and control of any matter which is subject to indemnification under
    this Section. In such case, you agree to cooperate with any reasonable
    requests assisting Aaptiv’s defense of such matter at your expense.
  </p>
  <p>16. Term and Termination of the EULA.</p>
  <div>
    <p>
      a. <u>Term</u>. As between you and Aaptiv, the Term of this EULA commences
      as of your first use of the Service and continues until the termination of
      this EULA by either you or Aaptiv.
    </p>
    <p>
      b. <u>Termination</u>. You may terminate this EULA by sending written
      notification to Aaptiv at support&#64;aaptiv.com, deleting the App from your
      mobile device, and terminating all other uses of the Service. Aaptiv
      reserves the right, in its sole discretion, to restrict, suspend, or
      terminate this EULA and your access to all or any part of the Service at
      any time without prior notice or liability if you breach any provision of
      this EULA. Aaptiv may further terminate this EULA for any other reason
      upon ten (10) days’ notice to you using the email address associated with
      your account credentials. Aaptiv reserves the right to change, suspend, or
      discontinue all or any part of the Service at any time without prior
      notice or liability.
    </p>
    <p>
      c. Sections 1, 3.b, 5.b, 5.c, 6.d, 6.e, 6.f, 7, 9 – 11, 15, 16.c, and 17 –
      20 and all defined terms used therein will survive the termination of this
      EULA indefinitely.
    </p>
  </div>
  <p>
    17. Consent to Electronic Communications. By using the Service, you consent
    to receiving certain electronic communications from us as further described
    in the Privacy Policy. Please read the Privacy Policy to learn more about
    your choices regarding our electronic communications practices.
  </p>
  <p>
    18. Miscellaneous. This EULA is governed by the substantive laws of the
    State of New York without respect to its conflict of law provisions. You
    expressly agree to submit to the exclusive personal jurisdiction of the
    state and federal courts sitting in New York County, New York. You agree
    that no joint venture, partnership, employment, or agency relationship
    exists between you and Aaptiv as a result of this EULA or use of the
    Service. You further acknowledge that by submitting User Content, no
    confidential, fiduciary, contractually implied, or other relationship is
    created between you and Aaptiv other than pursuant to this EULA. If any
    provision of this EULA is found to be invalid by any court having competent
    jurisdiction, the invalidity of such provision will not affect the validity
    of the remaining provisions of this EULA, which will remain in full force
    and effect. Failure of Aaptiv to act on or enforce any provision of this
    EULA will not be construed as a waiver of that provision or any other
    provision in this EULA. No waiver will be effective against Aaptiv unless
    made in writing, and no such waiver will be construed as a waiver in any
    other or subsequent instance. Except as expressly agreed by Aaptiv and you,
    this EULA constitutes the entire agreement between you and Aaptiv with
    respect to the subject matter hereof, and supersedes all previous or
    contemporaneous agreements, whether written or oral, between the parties
    with respect to the subject matter herein. The Section headings are provided
    merely for convenience and will not be given any legal import. This EULA
    will inure to the benefit of our successors and assigns. You may not assign
    this EULA or any of the rights or licenses granted hereunder, directly or
    indirectly, without the prior express written consent of Aaptiv. This means
    that in the event you dispose of any device on which you have installed the
    App, such as by sale or gift, you are responsible for deleting the App and
    any Aaptiv Content from your device prior to such disposition. Aaptiv may
    assign this EULA, including all its rights hereunder, without restriction.
  </p>
  <p>
    19. Contact Us. You may contact us in connection with your use of the
    Service by mail at Aaptiv, PO Box 270, New Rochelle NY 10804, and by email
    at support&#64;aaptiv.com.
  </p>
  <p>
    20. Open Source Software. The App contains certain open source software.
    Each item of open source software is subject to its own applicable license
    terms.
  </p>
  <p>
    NOTICE REGARDING APPLE. You acknowledge that this EULA is between you and
    Aaptiv only, not with Apple, and Apple is not responsible for the App or the
    content thereof. Apple has no obligation whatsoever to furnish any
    maintenance and support services with respect to the App. In the event of
    any failure of the App to conform to any applicable warranty, then you may
    notify Apple and Apple will refund the purchase price for the relevant App
    to you; and, to the maximum extent permitted by applicable law, Apple has no
    other warranty obligation whatsoever with respect to the App. Apple is not
    responsible for addressing any claims by you or any third party relating to
    the App or your possession and/or use of the App, including, but not limited
    to: (i) product liability claims; (ii) any claim that the App fails to
    conform to any applicable legal or regulatory requirement; and (iii) claims
    arising under consumer protection or similar legislation. Apple is not
    responsible for the investigation, defense, settlement, and discharge of any
    third party claim that the App or your possession and use of the App
    infringes that third party’s intellectual property rights. You agree to
    comply with any applicable third party terms, when using the App. Apple, and
    Apple’s subsidiaries, are third party beneficiaries of this EULA, and upon
    your acceptance of this EULA, Apple will have the right (and will be deemed
    to have accepted the right) to enforce this EULA against you as a third
    party beneficiary of this EULA. You hereby represent and warrant that (i)
    you are not located in a country that is subject to a U.S. Government
    embargo, or that has been designated by the U.S. Government as a “terrorist
    supporting” country; and (ii) you are not listed on any U.S. Government list
    of prohibited or restricted parties. If Aaptiv provides a translation of the
    English language version of this EULA, the translation is provided solely
    for convenience, and the English version will prevail.
  </p>
</div>
