import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppBrowserModule } from '@app/app.browser.module';
import { datadogLogs } from '@datadog/browser-logs';
import { VERSION } from '@environments/version';
import { init } from '@sentry/angular';
import { environment } from './environments/_current/environment';
const isProductionMode = environment.productionMode;
let initializeGoogleAnalytics = null;

if (isProductionMode) {
  init({
    dsn: environment.sentryDsn,
    environment: environment.serverEnvironment,
    release: 'tv-ui@' + VERSION?.version,
  });

  datadogLogs.init({
    clientToken: environment.datadog?.clientToken,
    site: 'datadoghq.com',
    env: environment.serverEnvironment,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
  });

  enableProdMode();

  const dataLayer: unknown[] = [];
  initializeGoogleAnalytics = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsTrackingId}`;
    script.onload = () => {
      function gtag(...rest: [command: string, config: string]) {
        dataLayer.push(...rest);
      }
      gtag('js', 'gtag/js');
      gtag('config', environment.googleAnalyticsTrackingId);
    };
    document.head.appendChild(script);
  };
}

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .catch((err) => console.error(err));
}

if (document?.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

if (isProductionMode) {
  initializeGoogleAnalytics();
}
