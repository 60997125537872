import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { AboutModule } from '@app/core/modules/about/about.module';
import { SharedButtonModule } from '@app/core/modules/buttons/shared-button.module';
import { TermsAndConditionsDialogModule } from '@app/core/modules/terms-and-conditions-dialog/terms-and-conditions-dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { AlertsModule } from '@skyfit/core-web-library/components/alerts';
import { PasswordFieldModule } from '@skyfit/core-web-library/components/password-field';
import { QRCodeModule } from 'angularx-qrcode';
import { AUTHENTICATION_COMPONENTS } from './authentication.module.components';
import { routes } from './authentication.routes';

@NgModule({
  imports: [
    AboutModule,
    AlertsModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    PasswordFieldModule,
    QRCodeModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    SharedButtonModule,
    TranslateModule,
    TermsAndConditionsDialogModule,
  ],
  declarations: [...AUTHENTICATION_COMPONENTS],
  exports: [...AUTHENTICATION_COMPONENTS],
})
export class AuthenticationModule {}
