import { Injectable } from '@angular/core';
import { AaptivAuthService } from '@skyfit/core-web-library/aaptiv-services/authenticaton';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthActivateGuard {
  constructor(private _aaptivAuthService: AaptivAuthService) {}

  canActivate(): Observable<boolean> {
    return this._aaptivAuthService.isSignedIn().pipe(
      map((isSignedIn) => {
        if (isSignedIn) {
          return true;
        } else {
          this.navigateSignIn();
          return false;
        }
      })
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }

  private async navigateSignIn(): Promise<void> {
    await this._aaptivAuthService.logoutRedirect();
  }
}
