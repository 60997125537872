import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { KeyCodes } from 'src/models/keyCodes';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnInit, AfterViewInit, OnChanges {
  @Output() updateIndex = new EventEmitter<number>();
  @Output() enterPressed = new EventEmitter<number>();
  @Output() itemsLoaded = new EventEmitter<void>();

  @Output() leftEdgeMovement = new EventEmitter<number>();
  @Output() rightEdgeMovement = new EventEmitter<number>();
  @Output() topEdgeMovement = new EventEmitter<number>();
  @Output() bottomEdgeMovment = new EventEmitter<number>();

  @Input() activeIndex: number;
  @Input() currentNodeIndex: number;
  // defaults are optimized for workout cards
  @Input() itemsPerRow: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() items: QueryList<any>; // needs to be a component with setFocus OR does parent handle what happens on index change?

  @Input() allowLeftMovement = true;
  @Input() allowRightMovement = true;
  @Input() allowUpMovement = true;
  @Input() allowDownMovement = true;

  @Input() workoutGrid: boolean;

  gridColStyle: string;

  @HostListener('keydown', ['$event']) keydown(event: KeyboardEvent): void {
    switch (event.code || event.key) {
      case KeyCodes.arrowUp:
        event.preventDefault();
        this.onArrowUp();
        break;
      case KeyCodes.arrowDown:
        event.preventDefault();
        this.onArrowDown(event);
        break;
      case KeyCodes.arrowLeft:
        this.onArrowLeft();
        break;
      case KeyCodes.arrowRight:
        event.preventDefault();
        this.onArrowRight();
        break;
      case KeyCodes.enter:
        this.onEnter();
        break;
    }
  }

  ngOnInit(): void {
    this.gridColStyle = `repeat(${this.itemsPerRow}, 1fr)`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemsPerRow) {
      this.gridColStyle = `repeat(${this.itemsPerRow}, 1fr)`;
    }
  }

  ngAfterViewInit(): void {
    this.itemsLoaded.emit();
  }

  onArrowUp(): void {
    if (this.currentNodeIndex >= this.itemsPerRow && this.allowUpMovement) {
      const newIndex = this.currentNodeIndex - this.itemsPerRow;
      if (newIndex !== this.activeIndex) {
        this.updateIndex.emit(newIndex);
        this.items?.get(newIndex)?.setFocus();
      } else if (this.activeIndex > 0) {
        this.updateIndex.emit(newIndex - 1);
        this.items?.get(newIndex - 1)?.setFocus();
      }
    } else {
      this.topEdgeMovement.emit(this.currentNodeIndex);
    }
  }

  onArrowDown(event: KeyboardEvent): void {
    if (
      this.currentNodeIndex < this.items?.length - this.itemsPerRow &&
      this.allowDownMovement
    ) {
      const newIndex = this.currentNodeIndex + this.itemsPerRow;
      if (newIndex !== this.activeIndex) {
        this.updateIndex.emit(newIndex);
        this.items?.get(newIndex)?.setFocus();
      } else if (this.items?.length - this.activeIndex) {
        this.updateIndex.emit(newIndex + 1);
        this.items?.get(newIndex + 1)?.setFocus();
      }
    } else {
      event.preventDefault();
    }
  }

  onArrowLeft(): void {
    if (this.currentNodeIndex > 0 && this.allowLeftMovement) {
      const newIndex = this.currentNodeIndex - 1;
      this.updateIndex.emit(newIndex);
      this.items?.get(newIndex)?.setFocus();
    } else {
      this.leftEdgeMovement.emit(this.currentNodeIndex);
    }
  }

  onArrowRight(): void {
    if (
      this.currentNodeIndex < this.items?.length - 1 &&
      this.allowRightMovement
    ) {
      const newIndex = this.currentNodeIndex + 1;
      this.updateIndex.emit(newIndex);
      this.items?.get(newIndex)?.setFocus();
    } else {
      this.rightEdgeMovement.emit(this.currentNodeIndex);
    }
  }

  onEnter(): void {
    this.enterPressed.emit(this.currentNodeIndex);
  }
}
